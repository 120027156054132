.overview{
    /* width: 100%; */
    padding: 20px;
}

.overview-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    padding: 25px 20px;
    border-radius: 5px;
    /* color: white; */
    background-color: var(--secondary-color);
    /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1); */
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}