.sidenav {
    background-color: #1c3472;
    width: 200px;
    height: 100vh;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
}
.sidenav img.pfp {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}
.sidenav h3{
    color: cornsilk;
    padding-bottom: 26px;

}
.sidenav .nav-link{
    color: cornsilk;
    width: 100%;
    text-align: center;
    height: 20px;
    font-size: 14px;
}