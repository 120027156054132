.news-crud {
    padding: 20px;
    position: relative;
    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
}
.data-input-container {
    /* width: 80%; */
}
.data-input-container input{
    width: 200px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.data-input-container textarea{
    width: calc(100% - 20px);
    height: 250px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    /* resize: vertical; */
    resize: none;

    

}
.data-input-container input.date{
    width: 30%;
}
.data-input-container input.title {
    width: 70%;
}


.news-crud .buttons{
    margin: 1em 1em 0em 1em;
    width: 100%;
    flex-wrap: wrap;
}
.news-crud .media-item{
 display: flex;
 flex-direction: column;
 justify-content: center;
 gap: 0.5em;   
}
.news-crud .media-item img{
    width: 140px;
    height: 140px;
}