.news {
    padding: 20px;
    height: calc(100vh - 60px);
    overflow-y: auto;
}
.post {
    background-color: var(--secondary-color);
    padding: 20px;
    border-radius: 4px;
    height: 200px;
    position: relative;
}
.post img {
    height: 160px;
    width: 160px
}
.post .link{
    position: absolute;
    bottom: 20px;
    right: 20px;
}
.post .content{
    width: 100%;
}