header {
    background-color: var(--primary-color);
    height: 60px;
    position: static;
    width: 100%;
    min-width: calc(100vw - 200px);
    /* z-index: 1; */
    /* box-shadow: 0px 2px 4px #ffffff40; */
    transition: all 0.3s ease-in-out;
    

}
header h2{
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    color: cornsilk;
}