.login {
    width: 100vw;
    height: 100vh;
    background-color: #7492dd;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


}

.login h1,.login h2,.login h3, .login p {
    color: white
}

.login .header img {
    height: 100px;
}
.login .login-container {
    width: 400px;
    height: 400px;
    overflow: auto;
    max-width: 90vw;
    max-height: 90vh;
    background-color: var(--primary-color);
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    padding: 1em
}
.login input{
    width: 200px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 37px;
}