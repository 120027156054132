.medical-network-crud {
    padding: 20px;
    position: relative;
    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
}
.medical-network-crud input.form-item {
    width: 200px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.medical-network-crud select.form-item {
    width: 200px;
    height: 37px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
}
.medical-network-crud label {
    font-size: 17px;
    font-weight: bold;
    color: #333;    
}
.medical-network-crud .form-group {
    margin-bottom: 10px;
}
.white{
    color: #ddd;
}

.medical-network-crud .buttons{
    bottom: 30px;
    width: 100%;
}
.form-group{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}
